
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/stea.png'
import "../css/ImgInvitatie.css"
import background from "../images/f1.jpg"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5 py-2" style={{ backgroundImage: `url(${background})` }}>
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4"  >
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <h5>
                            Eu sunt bebe
                        </h5>
                        <h4>Arsenuș</h4>
                        <h5>și împreună cu părinții</h5>
                        <h4>Ion & Raisa</h4>
                        <h5>și frățiorii</h5>
                        <h4>Victorița și Gheorhiță</h4>
                        <h5>Vă invităm cu plecăciune, <br/>la cumetria mea, <br/>care va avea loc la <br/>
                        <b style={{color: 'white', fontSize:'24px',  textShadow: '.5px .5px 1px rgb(7, 7, 7)'}} >29 Iulie 2023</b>, <br/>
                        ora <b style={{color: 'white', fontSize:'24px',  textShadow: '.5px .5px 1px rgb(7, 7, 7)'}}>16:00</b>, <br />
                          
                        
                       la Restaurantul <br/> <b style={{color: 'white', fontSize:'24px',  textShadow: '.5px .5px 1px rgb(7, 7, 7)'}}>„Palatul Regal”</b>, <br/>
                       str. Alba Iulia 190/1, Chişinău.
                       </h5>
                        
                        <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                            <svg width="1.5em" height="1.5em" color="white" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                            </svg>
                            <br />
                        </h5>
                        



                        <img id="mikey_img" src={mikey} />




                        {/** <ImgInvitatie />*/}

                        <h4 class="card-footer">Abia aștept să ne cunoaștem!</h4>
                        {/**   <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>*/}
                    </div>




                </div>
            </div>
        </section>

    )
}

export default CardInvitatie;