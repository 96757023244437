import restaurant from "./images/regal1.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/f21m.jpg";
import imgheader from "./images/f2m.jpg";
import imgheadermiini from "./images/f4.jpg";
import imgheadermiinimb from "./images/f4.jpg";
import logo from './images/stea.png'



const data = {
    introData: [{
        copilul: "Arsenuș",
        familia: "familia Lupu",
        logo: logo,
        tata: "Ion",
        mama: "Raisa",
        data: "29 Iulie 2023",
        data_confirmare: "10 iulie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "raisa.antonov@gmail.com", 
       tel: "+373 601 30 091",
       phone: "tel:+37360130091",
       viber: "viber://chat?number=%2B37360130091",
       whatsapp: "https://wa.me/+37360130091",
       messenger: "https://www.messenger.com/t/graur.antonov.raisa",
       tel1: "+373 602 00 563",
       phone1: "tel:+37360200563",
       viber1: "viber://chat?number=%2B37360200563",
       whatsapp1: "https://wa.me/+37360200563",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
   
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Palatul Regal",
            data: "29 iulie 2023, sâmbătă, ora 16:00",
            adress: "Strada Alba Iulia 190/1, Chişinău",
            harta: "https://goo.gl/maps/EHpKFwYF19hREWxe8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10876.489650235868!2d28.7721767!3d47.0378287!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d0614cdf039%3A0x310c59f8aaf19c2!2sPalatul%20Regal!5e0!3m2!1sro!2s!4v1687943483631!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copiii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, sunt pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;